import { h, createApp } from 'vue'
import singleSpaVue from 'single-spa-vue'
import { plugin, defaultConfig } from '@formkit/vue'
import config from './formkit.config'

import App from './App.vue'
import { i18n } from '@/i18n'
import router from '@/router'

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render () {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecycle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      })
    }
  },
  handleInstance: (app) => {
    app
      .use(i18n)
      .use(router)
      .use(plugin, defaultConfig(config))
  }
})

export const bootstrap = vueLifecycles.bootstrap
export const mount = vueLifecycles.mount
export const unmount = vueLifecycles.unmount
