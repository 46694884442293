import { createInput, DefaultConfigOptions } from '@formkit/vue'
import { fr } from '@formkit/i18n'
import { generateClasses } from '@formkit/themes'

import InputTotp from '@/components/InputTotp.vue'

const config: DefaultConfigOptions = {
  config: {
    classes: generateClasses({
      totp: {
        inner: '$reset'
      }
    })
  },
  inputs: {
    totp: createInput(InputTotp, {
      props: ['digits']
    })
  },
  locales: { fr },
  locale: 'fr',
  theme: 'genesis'
}

export default config
