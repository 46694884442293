"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var getPasswordErrors = function () {
    return {
        min_length_digit: 'Votre mot de passe doit contenir au moins un chiffre',
        min_length_lower_characters: 'Votre mot de passe doit contenir au moins une minuscule',
        min_length_special_characters: 'Votre mot de passe doit contenir au moins un caractère spécial',
        min_length_upper_characters: 'Votre mot de passe doit contenir au moins une majuscule',
        password_entirely_numeric: 'Votre mot de passe ne peut contenir uniquement des chiffres',
        password_required: 'Mot de passe requis',
        password_requirements: 'Le mot de passe doit contenir au moins 8 caractères',
        password_too_common: 'Votre mot de passe est trop commun',
        password_too_short: 'Votre mot de passe doit contenir au moins 12 caractères',
        passwords_mismatch: 'Les deux champs ne sont pas identiques',
        unauthorized: 'Identifiant ou mot de passe invalide'
    };
};
var translations = function () {
    return {
        'email-validate': {
            form: {
                errors: {
                    email_validation: {
                        email_already_validated: 'Votre email est déjà validé'
                    }
                }
            },
            labels: {
                activate_title: 'Activation de votre compte'
            }
        },
        date: {
            hrdatetimeformat: 'D MMM YYYY, H[h]mm'
        },
        'invitation-accept': {
            form: {
                buttons: {
                    submit: 'Valider'
                },
                errors: {
                    email_validation: {
                        email_already_validated: 'Votre email est déjà validé'
                    },
                    password1: getPasswordErrors(),
                    password2: getPasswordErrors()
                },
                labels: {
                    password: 'Nouveau mot de passe',
                    'confirm-password': 'Confirmer le nouveau mot de passe'
                }
            },
            labels: {
                activate_title: 'Activation de votre compte',
                title: 'Changez votre mot de passe'
            }
        },
        login: {
            form: {
                buttons: {
                    submit: 'Se connecter',
                    'password-reset': 'Mot de passe oublié ?'
                },
                labels: {
                    email: 'Entreprise{\'@\'}email.com',
                    password: 'Mot de passe',
                    phone_number: 'Numéro de téléphone'
                },
                errors: __assign(__assign({}, getPasswordErrors()), { banned: 'Compte suspendu jusqu\'au {0}.', email: 'Adresse email incorrecte', totp_error: 'Échec de Double Authentification' })
            },
            ka_link: 'Vous êtes une Grande Entreprise ?',
            labels: {
                tfa: {
                    continue: 'Continuer',
                    enable: 'Activer',
                    enable_tfa: 'Activer l\'authentificateur',
                    enter_code: 'Entrez le code de sécurité pour continuer',
                    setup_code: 'Code de sécurité',
                    steps: {
                        1: {
                            content: 'Téléchargez et installez Google Authenticator sur votre téléphone.',
                            title: 'Télécharger Google Authenticator'
                        },
                        2: {
                            content: 'Ouvrez Google Authenticator et scannez l\'image sur la droite depuis votre téléphone.',
                            title: 'Scanner le QR Code'
                        },
                        3: {
                            content: 'Entrez le code de sécurité pour continuer.',
                            title: 'Connexion avec code de sécurité'
                        },
                        title: 'Sécurisez votre compte en 3 étapes simples :'
                    }
                },
                title: 'Connectez-vous à {APP_NAME}'
            },
            sso: {
                form: {
                    buttons: {
                        submit: 'Se connecter'
                    },
                    errors: {
                        customer: {
                            invalid: 'Le champ n\'est pas valide'
                        }
                    },
                    labels: {
                        company: 'Nom de l\'entreprise'
                    }
                },
                labels: {
                    button: 'Single Sign-On (SSO)',
                    close: 'Fermer',
                    customer: 'Nom de l\'entreprise',
                    email: 'Email',
                    error: {
                        login_error: 'Une erreur s\'est produite',
                        login_timeout: 'Délai de connexion dépassé',
                        unauthorized: 'Accès non autorisé'
                    },
                    or: 'Ou se connecter avec'
                }
            },
            title: 'Connectez-vous à {APP_NAME}'
        },
        'password-change': {
            form: {
                errors: {
                    password1: getPasswordErrors(),
                    password2: getPasswordErrors()
                },
                labels: {
                    password: 'Nouveau mot de passe',
                    'confirm-password': 'Confirmer le nouveau mot de passe'
                }
            },
            labels: {
                title: 'Modifier votre mot de passe'
            },
            links: {
                'back-to-login': 'Connectez-vous'
            }
        },
        'password-reset': {
            form: {
                buttons: {
                    submit: 'Réinitialiser mon mot de passe'
                },
                labels: {
                    email: 'Adresse email'
                }
            },
            labels: {
                already_registered: 'Vous avez déjà un compte ?',
                back_to_login: 'Retour à la page de connexion',
                email_has_been_sent: 'Email envoyé',
                email_has_been_sent_check_mailbox_incl_spam: 'Un mail de confirmation vient de vous être envoyé afin de valider votre adresse mail. Merci de vérifier qu\'il ne soit pas arrivé dans vos spams.',
                title: 'Réinitialisation du mot de passe'
            },
            links: {
                'back-to-login': 'Connectez-vous'
            }
        },
        'user-not-configured': {
            labels: {
                body: 'Les permissions de votre compte utilisateur n\'ont pas été définies, veuillez contacter votre administrateur afin de finaliser la configuration de votre compte.',
                title: 'Configuration de votre compte en cours'
            }
        }
    };
};
exports.default = translations();
