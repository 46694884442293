import { createRouter, createWebHistory } from 'vue-router'

function loadView (view: string) {
  return () => import(
    /* webpackChunkName: "view-[request]" */
    `@/views/${view}.vue`
  )
}

const routes = [
  {
    path: '/confirm-email',
    name: 'confirm-email',
    component: loadView('ConfirmEmail'),
    meta: {
      permission: 'agent',
      requiresAuth: true,
      notRequiresEmailConfirm: true
    }
  },
  {
    path: '/email/validate/:uid/:token',
    name: 'email-validate',
    component: loadView('EmailValidate')
  },
  {
    path: '/invitation/:uid/:token',
    name: 'invitation',
    component: loadView('InvitationAccept')
  },
  {
    path: '/login',
    name: 'login',
    component: loadView('LogIn')
  },
  {
    path: '/logout/',
    name: 'logout',
    component: loadView('LogOut')
  },
  {
    path: '/m/',
    name: 'masquerade',
    component: loadView('MasqueradeUser')
  },
  {
    path: '/password/reset',
    name: 'password-reset',
    component: loadView('PasswordReset')
  },
  {
    path: '/password/:uid/:token',
    name: 'password-change',
    component: loadView('PasswordChange')
  },
  {
    path: '/user-not-configured',
    name: 'user-not-configured',
    component: loadView('UserNotConfigured')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes // short for `routes: routes`
})

export default router
