"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var getPasswordErrors = function () {
    return {
        min_length_digit: 'Your password must contain at least one digit',
        min_length_lower_characters: 'Your password must contain at least one lowercase character',
        min_length_special_characters: 'Your password must contain at least one special character',
        min_length_upper_characters: 'Your password must contain at least one uppercase letter',
        password_entirely_numeric: 'Your password cannot contain only numbers',
        password_required: 'Password required',
        password_requirements: 'Password must contain at least 8 characters',
        password_too_common: 'Your password is too common',
        password_too_short: 'Your password must be at least 12 characters long',
        passwords_mismatch: 'The two fields are not identical'
    };
};
var translations = function () {
    return {
        'email-validate': {
            form: {
                errors: {
                    email_validation: {
                        email_already_validated: 'Your email is already validated'
                    }
                }
            },
            labels: {
                activate_title: 'Activating your account'
            }
        },
        date: {
            hrdatetimeformat: 'MMM D, YYYY h:mm A'
        },
        'invitation-accept': {
            form: {
                buttons: {
                    submit: 'Validate'
                },
                errors: {
                    email_validation: {
                        email_already_validated: 'Your email is already validated'
                    },
                    password1: getPasswordErrors(),
                    password2: getPasswordErrors()
                },
                labels: {
                    password: 'New password',
                    'confirm-password': 'Confirm new password'
                }
            },
            labels: {
                activate_title: 'Activating your account',
                title: 'Change your password'
            }
        },
        login: {
            form: {
                buttons: {
                    submit: 'Connect',
                    'password-reset': 'Forgot password?'
                },
                labels: {
                    email: 'Entreprise{\'@\'}email.com',
                    password: 'Password',
                    phone_number: 'Phone number'
                },
                errors: __assign(__assign({}, getPasswordErrors()), { unauthorized: 'Invalid username or password', banned: 'Account suspended until {0}.', email: 'Incorrect email address', totp_error: 'Two-Factor Authentication failed' })
            },
            ka_link: 'Are you a Large Company?',
            labels: {
                tfa: {
                    continue: 'Continue',
                    enable: 'Enable',
                    enable_tfa: 'Enable the authenticator',
                    enter_code: 'Enter security code to continue',
                    setup_code: 'Security code',
                    steps: {
                        1: {
                            content: 'Download and install Google Authenticator on your phone.',
                            title: 'Download Google Authenticator'
                        },
                        2: {
                            content: 'Open Google Authenticator and scan the image on the right from your phone.',
                            title: 'Scan the QR Code'
                        },
                        3: {
                            content: 'Enter security code to continue.',
                            title: 'Login with security code'
                        },
                        title: 'Secure your account in 3 simple steps:'
                    }
                },
                title: 'Connect to {APP_NAME}'
            },
            sso: {
                form: {
                    buttons: {
                        submit: 'Login'
                    },
                    errors: {
                        customer: {
                            invalid: 'Field is invalid'
                        }
                    },
                    labels: {
                        company: 'Company name'
                    }
                },
                labels: {
                    button: 'Single Sign-On (SSO)',
                    close: 'Close',
                    customer: 'Company name',
                    email: 'Email',
                    error: {
                        login_error: 'An error has occured',
                        login_timeout: 'Connection time out',
                        unauthorized: 'Unauthorized access'
                    },
                    or: 'Or sign in with'
                }
            },
            title: 'Log in to {APP_NAME}'
        },
        'password-change': {
            form: {
                errors: {
                    password1: getPasswordErrors(),
                    password2: getPasswordErrors()
                },
                labels: {
                    password: 'New password',
                    'confirm-password': 'Confirm new password'
                }
            },
            labels: {
                title: 'Change your password'
            },
            links: {
                'back-to-login': 'Log in'
            }
        },
        'password-reset': {
            form: {
                buttons: {
                    submit: 'Reset my password'
                },
                labels: {
                    email: 'Email address'
                }
            },
            labels: {
                already_registered: 'Already have an account?',
                back_to_login: 'Back to login page',
                email_has_been_sent: 'Email sent',
                email_has_been_sent_check_mailbox_incl_spam: 'A confirmation email has just been sent to you to validate your email address. Please check that it has not arrived in your spam folder.',
                title: 'Reset your password'
            },
            links: {
                'back-to-login': 'Log in'
            }
        },
        'user-not-configured': {
            labels: {
                body: 'Your user account permissions have not been set. Please contact your administrator to finalize your account configuration.:',
                title: 'Account configuration in progress'
            }
        }
    };
};
exports.default = translations();
