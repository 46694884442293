import config from '@/formkit.config'
import { createI18n } from 'vue-i18n'

import { Locales } from './locales'
import en from './en-gb/index'
import fr from './fr-fr/index'

export const messages = {
  [Locales.EN]: en,
  [Locales.FR]: fr
}

let navigatorLanguage = navigator.language

if (navigatorLanguage.indexOf('-') > -1) {
  navigatorLanguage = navigatorLanguage.substring(
    0, navigatorLanguage.indexOf('-'))
}

let defaultLanguage = 'en'
if (Object.keys(messages).indexOf(navigatorLanguage) > -1) {
  defaultLanguage = navigatorLanguage
}

let lsLanguage = localStorage.getItem('chayall_lang')
if (lsLanguage) {
  localStorage.setItem('gb:chayall:cms:language', lsLanguage)
} else {
  lsLanguage = localStorage.getItem('gb:chayall:cms:language')
}
if (lsLanguage) {
  defaultLanguage = lsLanguage
}

const defaultLocale = defaultLanguage

config.locale = defaultLanguage

const i18n = createI18n({
  messages,
  legacy: false,
  globalInjection: true,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
})

export {
  defaultLocale,
  i18n
}
