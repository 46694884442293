"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LOCALES = exports.Locales = void 0;
var Locales;
(function (Locales) {
    Locales["EN"] = "en";
    Locales["FR"] = "fr";
})(Locales = exports.Locales || (exports.Locales = {}));
exports.LOCALES = [
    { value: Locales.EN, caption: 'English' },
    { value: Locales.FR, caption: 'Français' }
];
